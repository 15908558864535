import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
// import Arrow from '../templates/page'

import { Link } from 'gatsby'
import styled from 'styled-components'
import Arrow from './Arrow'

import facility from '../images/facility.jpg'
import harness from '../images/harness.jpg'

const slides = [
  {
    id: 2,
    name: 'Services',
    subtitle: 'Reliable Partnership',
    description:
      'Mega Techway has the engineering and design for manufacturability expertise to ensure the success of your program.',
    image: harness,
    link: '/services',
  },
  {
    id: 1,
    subtitle: 'Wire Harness Experts',
    name: 'Markets',
    description:
      'World-class manufacturers in automotive, industrial, medical, telecom and other industries rely on Mega Techway as a trusted supply chain partner for their custom cable harnesses and assemblies.',
    image:
      'https://images.unsplash.com/photo-1505855796860-aa05646cbf1f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80',
    link: 'markets',
  },
  {
    id: 3,
    name: 'Manufacturing',
    subtitle: 'Custom Interconnect Solutions',
    description:
      'Manufacturers around the world rely on Mega Techway’s operational expertise for quality, on-time cable assemblies and wiring harnesses.',
    image: facility,
    link: '/manufacturing',
  },
]

const Hero = styled.section`
  background: #efefef;
  width: 100%;
`
const SliderWrapper = styled.section`
  display: flex;

  max-height: 750px;
  overflow: hidden;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const Slide = styled.div`
  a {
    text-decoration: none;
    display: block;
    svg {
      margin-left: 0.5rem;
    }

    background: transparent;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    padding: 0;
    margin: 1rem 0;
  }
  flex: 1 auto;
  display: flex;
  padding: 2rem;
  box-sizing: border-box;
  width: 33.33333333%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.6);
  transition: height, width 700ms;

  @media (max-width: 700px) {
    width: 100%;
    height: 33%;
  }

  .line {
    height: 2px;
    background: #fff;
    width: 0px;
    margin: 0 100% 20px 0;
    margin-bottom: 34px;
    transition: width cubic-bezier(0.68, -0.225, 0.265, 1.225) 0.2s;
    transition-delay: 0.3s;
  }

  @keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    60% {
      -webkit-transform: translateY(-2px);
      -ms-transform: translateY(-2px);
      transform: translateY(-2px);
    }
  }

  &:hover:not(.active) span {
    animation-name: bounce;
    -moz-animation-name: bounce;
  }

  .inner {
    margin-top: 250px;
    color: #fff;

    z-index: 1;

    span {
      position: relative;
      font-weight: 300;
      display: block;
      text-transform: uppercase;

      letter-spacing: 1.16px;
      margin: 20px 0 14px;
      font-size: 14px;

      -webkit-animation-duration: 900ms;
      animation-duration: 900ms;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      -webkit-animation-iteration-count: 1;
    }

    h1 {
      text-transform: uppercase;

      text-transform: uppercase;
      font-size: 4vh;
      color: #ffffff;
      line-height: 54px;

      width: 650px;
      display: block;
    }

    .slide_body {
      max-width: 600px;

      p {
        font-family: 'Inter';
      }
    }
  }

  .background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    .background-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 0;
      overflow: hidden;

      &:after {
        display: block;
        height: 102%;
        width: 102%;
        position: absolute;
        top: -1%;
        right: -1%;
        bottom: -1%;
        left: -1%;
        background-color: #87001b;
        content: '';
        opacity: 0.5;

        transition: opacity 0.5s cubic-bezier(0.68, -0.225, 0.265, 1.225),
          transform 0.5s cubic-bezier(0.68, -0.225, 0.265, 1.225);
      }
    }

    .background-media {
      background-position: center;
      background-size: cover;

      height: 100%;
      width: 100%;

      img {
        display: none;
      }

      &:after {
        display: block;
        position: absolute;
        background-color: rgba(135, 0, 27, 0.3);
        background: linear-gradient(
          to bottom,
          rgba(135, 0, 27, 0.3),
          rgba(135, 0, 27, 0)
        );
        width: 100%;
        height: 150px;
        top: 0;
        right: 0;
        left: 0;
        content: '';
      }
    }
  }

  &.active .background-container:after {
    opacity: 0;
    visibility: hidden;
  }

  &:after {
    display: block;
    height: 102%;
    width: 102%;
    position: absolute;
    top: -1%;
    right: -1%;
    bottom: -1%;
    left: -1%;
    content: '';
    opacity: 0.8;
    transition: background-color 300ms;
  }

  &:nth-child(1):not(.active) {
    background: rgba(84, 10, 25, 1);
    /* background: rgba(84, 10, 25, 0.8); */
    z-index: 3;

    &:after {
      background: rgba(84, 10, 25, 0.8);
    }

    &:hover:after {
      background: rgba(84, 10, 25, 0.35);
    }
  }

  &:nth-child(2):not(.active) {
    background: rgba(135, 0, 27, 1);
    /* background: rgba(135, 0, 27, 0.8); */
    z-index: 2;

    &:after {
      background: rgba(135, 0, 27, 0.8);
      /* background: rgba(135, 0, 27, 1); */
      /* z-index: 1000; */
    }

    &:hover:after {
      background: rgba(135, 0, 27, 0.35);
    }
  }

  &:nth-child(3):not(.active) {
    background: rgba(84, 10, 25, 1);
    z-index: 1;

    &:after {
      background: rgba(84, 10, 25, 0.8);
    }

    &:hover:after {
      background: rgba(84, 10, 25, 0.35);
    }
  }

  .slide_body {
    /* display: none; */
    position: relative;
    transform: translateY(60px);
    /* top: 50px; */
    opacity: 0;
    /*
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    transition-delay: 500ms; */

    transition: opacity 0.25s cubic-bezier(0.68, 0, 0.265, 1) 0.4s,
      transform 0.45s cubic-bezier(0.68, 0, 0.265, 1) 0.4s;

    transition-property: color, opacity, transform;
    transition-duration: 0.25s, 0.2s, 0.2s;
    transition-timing-function: ease, cubic-bezier(0.68, 0, 0.265, 1),
      cubic-bezier(0.68, 0, 0.265, 1);
    transition-delay: 0s, 0.3s, 0.3s;
  }

  &.active {
    width: 65%;

    .line {
      width: 100px;
      transition-delay: 0s !important;
    }
    .slide_body {
      /* display: block; */
      transform: translateY(0);
      opacity: 1;
    }

    @media (max-width: 700px) {
      width: 100%;
      height: 750px;
    }

    position: relative;

    &::after {
      background-image: linear-gradient(
        -180deg,
        rgba(135, 0, 27, 0) 0%,
        rgba(135, 0, 27, 0.6) 100%
      );
    }

    .blur {
      background-image: linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.36) 100%
      );

      z-index: 1;
      position: absolute;
      content: '';
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
    }
  }
`

class Slider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 1,
      delay: 4500,
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, this.state.delay)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.delay !== this.state.delay) {
      clearInterval(this.interval)
      this.interval = setInterval(this.tick, this.state.delay)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }

  tick = () => {
    console.log('update tick')
    this.setState(prevState => ({
      active: (prevState.active + 1) % slides.length,
    }))
  }

  setActive = id => this.setState({ active: id })

  render() {
    return (
      <StaticQuery
        query={sliderQuery}
        render={data => {
          console.log(data.allWordpressPost.edges)

          return (
            <Hero>
              <SliderWrapper>
                {data.allWordpressPost.edges.map(({ node: slide }, index) => (
                  <Slide
                    key={slide.id}
                    className={this.state.active === index && 'active'}
                    onClick={() => this.setActive(index)}
                  >
                    <div className="inner">
                      {/* <span
                        dangerouslySetInnerHTML={{ __html: slide.excerpt }}
                      /> */}
                      <h1>{slide.title}</h1>

                      <div className="line" />

                      <div className="slide_body">
                        {/* <p>{slide.description} test</p> */}
                        <p
                          dangerouslySetInnerHTML={{ __html: slide.excerpt }}
                        />
                        <Link to={slide.slug}>
                          Learn More <Arrow />
                        </Link>
                      </div>
                    </div>
                    <div className="background">
                      <div className="background-container">
                        {slide.featured_media !== null && (
                          <div
                            className="background-media"
                            style={{
                              backgroundImage: `url(${
                                slide.featured_media.source_url
                              })`,
                            }}
                          >
                            <img src={slide.image} alt={slide.name} />
                            <div className="blur" />
                          </div>
                        )}
                      </div>
                    </div>
                  </Slide>
                ))}
              </SliderWrapper>
            </Hero>
          )
        }}
      />
    )
  }
}

const sliderQuery = graphql`
  query {
    allWordpressPost(
      limit: 3
      filter: { categories: { elemMatch: { name: { eq: "slider" } } } }
    ) {
      edges {
        node {
          id
          title
          excerpt
          content
          slug
          featured_media {
            source_url
            localFile {
              absolutePath
              childImageSharp {
                id
              }
            }
          }
          categories {
            id
            name
          }
        }
      }
    }
  }
`

export default Slider
