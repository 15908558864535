import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'

import SEO from '../components/seo'

import Slider from '../components/slider'

const Focus = styled.section`
  max-width: 1380px;
  margin: 5rem auto;

  h3 {
    margin: 0 auto;
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;

    color: #801726;
    font-size: 1.55rem;
  }

  h2 {
    font-size: 42px;
    width: 75%;
    margin: 0 auto;
    letter-spacing: 1px;
    font-weight: 300;
    text-align: center;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    color: #333;
    text-align: center;
  }
`

const Blocks = styled.section`
  /* background: #f4f4f4; */

  padding: 5rem;

  .title {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    color: #000;
    letter-spacing: 1.18px;
    position: relative;

    /* margin: 25px 100px; */
    margin-bottom: 3rem;

    &::before {
      height: 1px;
      background: rgba(0, 0, 0, 0.11);
      width: 42%;
      top: 6px;
      right: -20px;
      content: '';
      position: absolute;
    }

    &::after {
      height: 1px;
      background: rgba(0, 0, 0, 0.11);
      width: 42%;

      top: 6px;
      left: -20px;
      content: '';
      position: absolute;
    }
  }

  .inner {
    display: flex;
    max-width: 1380px;
    margin: 0 auto;
  }
`
const Block = styled.div`
  text-align: center;
  flex: 1;
  margin: 0 1rem;
  font-size: 16px;

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border-top: 2px solid #aaa;
    border-bottom: 2px solid #aaa;
    height: 95px;

    span {
      display: block;
      letter-spacing: 0px;
      margin-top: 5px;
      font-size: 32px;
    }
  }
`

const Features = styled.section`
  background: #141b22;
  color: #fff;

  margin: 2rem 0;
  .inner {
    max-width: 1280px;
    margin: 0rem auto;
    padding: 4.5rem 0;

    align-items: center;

    display: flex;
    justify-content: space-between;

    img {
      height: 150px;
    }
  }

  .alt {
    display: flex;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="MegaTech Way, Inc" keywords={[]} />

    <Slider />

    <StaticQuery
      query={graphql`
        query {
          wp {
            pageBy(uri: "home") {
              id
              title
              link
              content(format: RAW)
            }
          }
        }
      `}
    >
      {data => {
        return (
          <div>
            <Focus>
              <h3>Our Focus</h3>
              <h2>
                Quality manufacturing, reliability, and customer satisfaction.
                Empowering our customers to build inspiring solutions
              </h2>
            </Focus>

            <Blocks>
              <div className="inner">
                <Block>
                  <h3>
                    Design & <span>Engineering</span>
                  </h3>
                  <div>
                    Expertise in engineering and design for manufacturability.
                  </div>
                </Block>

                <Block>
                  <h3>
                    Commitment to <span>Quality</span>
                  </h3>
                  <div>
                    We meet IATF 16949:2016 and ISO 9001:2015 requirements, are
                    UL and CSA certified, and compliant with IPC-A-620 industry
                    standards.
                  </div>
                </Block>

                <Block>
                  <h3>
                    Application <span>Knowledge</span>
                  </h3>
                  <div>
                    Our program managers and engineering team have experience in
                    solving unique challenges of customers’ custom wire harness
                    and cable needs.
                  </div>
                </Block>
                <Block>
                  <h3>
                    Logistical <span>Reliability</span>
                  </h3>
                  <div>
                    Logistical support to ensure on-time deliveries, every time.
                  </div>
                </Block>
              </div>
            </Blocks>

            <Features>
              <div className="inner">
                <div>
                  <img
                    src="http://api.megatechway.com/wp-content/uploads/2019/04/ISO_TS_16949_2009.png"
                    alt="ISO/TS 16949:2009 Mega Techway "
                  />
                </div>
                <div>
                  <img
                    src="http://api.megatechway.com/wp-content/uploads/2019/04/whma.png"
                    alt="WHMA certified"
                  />
                </div>
                <div>
                  <img
                    src="http://api.megatechway.com/wp-content/uploads/2019/04/ISO-9001-2015-Mega-techway.png"
                    alt="ISO 9001:2015 Certified Mega Techway"
                  />
                </div>
                <div>
                  <img
                    src="http://api.megatechway.com/wp-content/uploads/2019/04/ul.png"
                    alt="UL/CSA Mega Techway"
                  />
                </div>
              </div>
            </Features>
          </div>
        )
      }}
    </StaticQuery>
    {/* <ContactForm /> */}
  </Layout>
)

export default IndexPage
